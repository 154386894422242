import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CommentsQueryParams,
  CommentsResponse,
  GetNotesQueryParam,
  NotesHotelsQueryParams,
  NotesHotelsResponse,
  ScheduleNote,
  Comment,
  AddNoteRequest,
  AddNoteResponse,
  SendNoteNowRequest,
} from '@core/models/schedule';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SchedulerService {
  private readonly baseUrl: string;
  private readonly v1BaseUrl: string;

  constructor(private http: HttpClient) {
    this.v1BaseUrl = `${environment.baseUrl}/v1/notes`
    this.baseUrl = `${environment.baseUrl}/v2/notes`;
  }

  getNotes(query: GetNotesQueryParam): Observable<ScheduleNote[]> {
    const params = this.httpParams(query);
    params['hotel'] = query.hotels[0];

    return this.http.post<ScheduleNote[]>(this.baseUrl, params);
  }

  getNotesHotels(query: NotesHotelsQueryParams): Observable<NotesHotelsResponse> {
    const params = this.httpParams(query);

    if (query?.enterprises) {
      params['enterprises'] = query.enterprises;
    } 
    if (query?.brands) {
      params['brands'] = query.brands;
    }
    if (query?.hotels) {
      params['hotels'] = query.hotels;
    }

    return this.http.post<NotesHotelsResponse>(`${this.baseUrl}/hotels`, params );
  }

  addComment(body: CommentsQueryParams): Observable<CommentsResponse> {
    return this.http.post<CommentsResponse>(`${this.baseUrl}/comments`, body);
  }

  getComment(noteId: string): Observable<Comment[]> {
    return this.http.get<Comment[]>(`${this.baseUrl}/${noteId}/comments`, {});
  }

  getComments(hotelId: string): Observable<CommentsResponse> {
    return this.http.post<CommentsResponse>(`${this.baseUrl}/hotels/${hotelId}/comments`, {});
  }

  addNote(body: AddNoteRequest): Observable<AddNoteResponse> {
    return this.http.post<AddNoteResponse>(`${this.v1BaseUrl}`, body);
  }

  updateNote(noteId: string, body: AddNoteRequest): Observable<AddNoteResponse> {
    const requestBody = {
      ...body,
      note_id: noteId
    }

    return this.http.put<AddNoteResponse>(`${this.baseUrl}/${noteId}/update`, requestBody);
  }

  sendNoteNow(noteId: string, emails: SendNoteNowRequest): Observable<unknown> {
    const requestBody = {
      sendTo: emails
    }

    return this.http.put<unknown>(`${this.baseUrl}/${noteId}/send_note_now`, requestBody);
  }

  private httpParams(
    query: GetNotesQueryParam | NotesHotelsQueryParams | string | any
  ): {[key: string]: any} {
    const params: any = {};

    if (query?.from && query?.to) {
      params['dateFrom'] = query.from;
      params['dateTo'] = query.to;
    }

    return params;
  }
}
