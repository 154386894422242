import { Component, OnInit } from '@angular/core';
import { Storage } from '@core/utils/storage';
import { User } from '@core/models';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];

  constructor() {}

  ngOnInit() {
    const user = Storage.getItem<User>('authUser');
    if (user && user.role.name === 'admin') {
      this.model = ADMIN_MENUS;
    }
    else if (user && user.role.name === 'enterprise admin') {
      this.model = ENTERPRISE_ADMIN_MENUS;
    }
    else {
      this.model = USER_MENUS;
    }
  }
}


const ADMIN_MENUS = [
  /*{
    label: 'Dashboard',
    icon: 'pi pi-home',
    items: [
      {
        label: 'Dashboard',
        icon: 'pi pi-fw pi-home',
        routerLink: ['/dashboard'],
      },
    ],
  },*/
  {
    label: 'Analytics',
    icon: 'pi pi-fw pi-chart-pie',
    items: [
      {
        label: 'Report',
        icon: 'pi pi-fw pi-compass',
        items: [
          {
            label: 'Property Report',
            icon: 'pi pi-fw pi-chart-bar',
            routerLink: ['/report/property'],
          },
          {
            label: 'Benchmarking Scorecard Beta',
            icon: 'pi pi-fw pi-palette',
            routerLink: ['/report/scorecard'],
          },
          {
            label: 'Report Scheduler',
            icon: 'pi pi-fw pi-chart-bar',
            routerLink: ['/report/scheduler'],
          },
          {
            label: 'Data Ingestion',
            icon: 'pi pi-fw pi-database',
            routerLink: ['/report/data-ingestion'],
          },
        ],
      },
      {
        label: 'Reviews',
        icon: 'pi pi-fw pi-filter',
        items: [
          {
            label: 'Dashboard',
            customIcon: 'assets/icons/dashboard.png',
            routerLink: ['/reviews/dashboard'],
          },
          {
            label: 'Responses',
            customIcon: 'assets/icons/responses.png',
            routerLink: ['/reviews/responses'],
          },
          {
            label: 'Sentiment',
            customIcon: 'assets/icons/emoticons.png',
            routerLink: ['/reviews/sentiment'],
          },
          {
            label: 'Comments',
            customIcon: 'assets/icons/comments.png',
            routerLink: ['/reviews/comments'],
          },
        ],
      },
      {
        label: 'Brand.com',
        icon: 'pi pi-th-large',
        items: [
          {
            label: 'Channel Mix',
            icon: 'pi pi-fw pi-sitemap',
            routerLink: ['/brand/channel-mix'],
          },
          {
            label: 'Visits & Revenue',
            icon: 'pi pi-fw pi-chart-line',
            routerLink: ['/brand/visits-revenue'],
          },
          {
            label: 'Source Traffic',
            icon: 'pi pi-chart-pie',
            routerLink: ['/brand/source-traffic'],
          },
          {
            label: 'Top Ref Domains',
            icon: 'pi pi-fw pi-at',
            routerLink: ['/brand/domains'],
          },
        ],
      },
      {
        label: 'Paid Media',
        icon: 'pi pi-fw pi-money-bill',
        items: [
          {
            label: 'Overview',
            icon: 'pi pi-fw pi-eye',
            routerLink: ['/media/overview'],
          },
          {
            label: 'Meta Search',
            icon: 'pi pi-fw pi-search',
            routerLink: ['/media/meta-search'],
          },
          {
            label: 'Sponsored Listings',
            icon: 'pi pi-fw pi-list',
            routerLink: ['/media/sponsored'],
          },
          {
            label: 'Paid Search',
            icon: 'pi pi-fw pi-search-plus',
            routerLink: ['/media/paid-search'],
          },
          {
            label: 'OTA',
            icon: 'pi pi-fw pi-circle',
            routerLink: ['/media/ota'],
          },
          {
            label: 'Display Ads',
            icon: 'pi pi-fw pi-book',
            routerLink: ['/media/display-ads'],
          },
        ],
      },
      {
        label: 'Social',
        icon: 'pi pi-fw pi-globe',
        items: [
          {
            label: 'Facebook',
            icon: 'pi pi-fw pi-facebook',
            routerLink: ['/social/facebook'],
          },
        ],
      },
    ],
  },
  {
    label: 'User & Master',
    icon: 'pi pi-fw pi-user',
    items: [
      {
        label: 'User Management',
        icon: 'pi pi-fw pi-user',
        items: [
          {
            label: 'User List',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/users'],
          },
          {
            label: 'User Create',
            icon: 'pi pi-fw pi-user-plus',
            routerLink: ['/users/create'],
          },
          /*{
            label: 'Role Master',
            icon: 'pi pi-fw pi-key',
            routerLink: ['/users/roles'],
          },*/
          /* {
            label: 'Role Permission',
            icon: 'pi pi-fw pi-shield',
            routerLink: ['/users/permission'],
          }, */
        ],
      },
      {
        label: 'Master',
        icon: 'pi pi-fw pi-building',
        items: [
          {
            label: 'Enterprise Master',
            icon: 'pi pi-fw pi-list',
            routerLink: ['/master/enterprises'],
          },
          {
            label: 'Brand Master',
            icon: 'pi pi-fw pi-list',
            routerLink: ['/master/brands'],
          },
          {
            label: 'Hotel Master',
            icon: 'pi pi-fw pi-list',
            routerLink: ['/master/hotels'],
          },
          {
            label: 'Sub Brand Master',
            icon: 'pi pi-fw pi-list',
            routerLink: ['/master/sub-brands'],
          },
          {
            label: 'KPI Calculation Trigger',
            icon: 'pi pi-fw pi-cog',
            routerLink: ['/master/cron'],
          },
          {
            label: 'Data Automation Logs',
            icon: 'pi pi-fw pi-list',
            routerLink: ['/master/data-automation-logs'],
          },
        ],
      },
    ],
  },
];

const ENTERPRISE_ADMIN_MENUS = [
  /*{
    label: 'Dashboard',
    icon: 'pi pi-home',
    items: [
      {
        label: 'Dashboard',
        icon: 'pi pi-fw pi-home',
        routerLink: ['/dashboard'],
      },
    ],
  },*/
  {
    label: 'Analytics',
    icon: 'pi pi-fw pi-chart-pie',
    items: [
      {
        label: 'Report',
        icon: 'pi pi-fw pi-compass',
        items: [
          {
            label: 'Property Report',
            icon: 'pi pi-fw pi-chart-bar',
            routerLink: ['/report/property'],
          },
          {
            label: 'Benchmarking Scorecard Beta',
            icon: 'pi pi-fw pi-palette',
            routerLink: ['/report/scorecard'],
          },
          {
            label: 'Report Scheduler',
            icon: 'pi pi-fw pi-chart-bar',
            routerLink: ['/report/scheduler'],
          },
          {
            label: 'Data Ingestion',
            icon: 'pi pi-fw pi-database',
            routerLink: ['/report/data-ingestion'],
          },
        ],
      },
      {
        label: 'Reviews',
        icon: 'pi pi-fw pi-filter',
        items: [
          {
            label: 'Dashboard',
            customIcon: 'assets/icons/dashboard.png',
            routerLink: ['/reviews/dashboard'],
          },
          {
            label: 'Responses',
            customIcon: 'assets/icons/responses.png',
            routerLink: ['/reviews/responses'],
          },
          {
            label: 'Sentiment',
            customIcon: 'assets/icons/emoticons.png',
            routerLink: ['/reviews/sentiment'],
          },
          {
            label: 'Comments',
            customIcon: 'assets/icons/comments.png',
            routerLink: ['/reviews/comments'],
          },
        ],
      },
      {
        label: 'Brand.com',
        icon: 'pi pi-th-large',
        items: [
          {
            label: 'Channel Mix',
            icon: 'pi pi-fw pi-sitemap',
            routerLink: ['/brand/channel-mix'],
          },
          {
            label: 'Visits & Revenue',
            icon: 'pi pi-fw pi-chart-line',
            routerLink: ['/brand/visits-revenue'],
          },
          {
            label: 'Source Traffic',
            icon: 'pi pi-chart-pie',
            routerLink: ['/brand/source-traffic'],
          },
          {
            label: 'Top Ref Domains',
            icon: 'pi pi-fw pi-at',
            routerLink: ['/brand/domains'],
          },
        ],
      },
      {
        label: 'Paid Media',
        icon: 'pi pi-fw pi-money-bill',
        items: [
          {
            label: 'Overview',
            icon: 'pi pi-fw pi-eye',
            routerLink: ['/media/overview'],
          },
          {
            label: 'Meta Search',
            icon: 'pi pi-fw pi-search',
            routerLink: ['/media/meta-search'],
          },
          {
            label: 'Sponsored Listings',
            icon: 'pi pi-fw pi-list',
            routerLink: ['/media/sponsored'],
          },
          {
            label: 'Paid Search',
            icon: 'pi pi-fw pi-search-plus',
            routerLink: ['/media/paid-search'],
          },
          {
            label: 'OTA',
            icon: 'pi pi-fw pi-circle',
            routerLink: ['/media/ota'],
          },
        ],
      },
      {
        label: 'Social',
        icon: 'pi pi-fw pi-globe',
        items: [
          {
            label: 'Facebook',
            icon: 'pi pi-fw pi-facebook',
            routerLink: ['/social/facebook'],
          },
        ],
      },
    ],
  },
  {
    label: 'User & Master',
    icon: 'pi pi-fw pi-user',
    items: [
      {
        label: 'User Management',
        icon: 'pi pi-fw pi-user',
        items: [
          {
            label: 'User List',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/users'],
          },
          {
            label: 'User Create',
            icon: 'pi pi-fw pi-user-plus',
            routerLink: ['/users/create'],
          },
          /*{
            label: 'Role Master',
            icon: 'pi pi-fw pi-key',
            routerLink: ['/users/roles'],
          },*/
          /* {
            label: 'Role Permission',
            icon: 'pi pi-fw pi-shield',
            routerLink: ['/users/permission'],
          }, */
        ],
      },
      {
        label: 'Master',
        icon: 'pi pi-fw pi-building',
        items: [
          {
            label: 'Enterprise Master',
            icon: 'pi pi-fw pi-list',
            routerLink: ['/master/enterprises'],
          },
          {
            label: 'Hotel Master',
            icon: 'pi pi-fw pi-list',
            routerLink: ['/master/hotels'],
          },
        ],
      },
    ],
  },
];

const USER_MENUS = [
  /*{
    label: 'Dashboard',
    icon: 'pi pi-home',
    items: [
      {
        label: 'Dashboard',
        icon: 'pi pi-fw pi-home',
        routerLink: ['/dashboard'],
      },
    ],
  },*/
  {
    label: 'Analytics',
    icon: 'pi pi-fw pi-chart-pie',
    items: [
      {
        label: 'Report',
        icon: 'pi pi-fw pi-compass',
        items: [
          {
            label: 'Property Report',
            icon: 'pi pi-fw pi-chart-bar',
            routerLink: ['/report/property'],
          },
          {
            label: 'Benchmarking Scorecard Beta',
            icon: 'pi pi-fw pi-palette',
            routerLink: ['/report/scorecard'],
          },
          {
            label: 'Report Scheduler',
            icon: 'pi pi-fw pi-chart-bar',
            routerLink: ['/report/scheduler'],
          },
          {
            label: 'Data Ingestion',
            icon: 'pi pi-fw pi-database',
            routerLink: ['/report/data-ingestion'],
          },
        ],
      },
      {
        label: 'Reviews',
        icon: 'pi pi-fw pi-filter',
        items: [
          {
            label: 'Dashboard',
            customIcon: 'assets/icons/dashboard.png',
            routerLink: ['/reviews/dashboard'],
          },
          {
            label: 'Responses',
            customIcon: 'assets/icons/responses.png',
            routerLink: ['/reviews/responses'],
          },
          {
            label: 'Sentiment',
            customIcon: 'assets/icons/emoticons.png',
            routerLink: ['/reviews/sentiment'],
          },
          {
            label: 'Comments',
            customIcon: 'assets/icons/comments.png',
            routerLink: ['/reviews/comments'],
          },
        ],
      },
      {
        label: 'Brand.com',
        icon: 'pi pi-th-large',
        items: [
          {
            label: 'Channel Mix',
            icon: 'pi pi-fw pi-sitemap',
            routerLink: ['/brand/channel-mix'],
          },
          {
            label: 'Visits & Revenue',
            icon: 'pi pi-fw pi-chart-line',
            routerLink: ['/brand/visits-revenue'],
          },
          {
            label: 'Source Traffic',
            icon: 'pi pi-chart-pie',
            routerLink: ['/brand/source-traffic'],
          },
          {
            label: 'Top Ref Domains',
            icon: 'pi pi-fw pi-at',
            routerLink: ['/brand/domains'],
          },
        ],
      },
      {
        label: 'Paid Media',
        icon: 'pi pi-fw pi-money-bill',
        items: [
          {
            label: 'Overview',
            icon: 'pi pi-fw pi-eye',
            routerLink: ['/media/overview'],
          },
          {
            label: 'Meta Search',
            icon: 'pi pi-fw pi-search',
            routerLink: ['/media/meta-search'],
          },
          {
            label: 'Sponsored Listings',
            icon: 'pi pi-fw pi-list',
            routerLink: ['/media/sponsored'],
          },
          {
            label: 'Paid Search',
            icon: 'pi pi-fw pi-search-plus',
            routerLink: ['/media/paid-search'],
          },
          {
            label: 'OTA',
            icon: 'pi pi-fw pi-circle',
            routerLink: ['/media/ota'],
          },
          {
            label: 'Display Ads',
            icon: 'pi pi-fw pi-circle',
            routerLink: ['/media/display-ads2'],
          },
        ],
      },
      {
        label: 'Social',
        icon: 'pi pi-fw pi-globe',
        items: [
          {
            label: 'Facebook',
            icon: 'pi pi-fw pi-facebook',
            routerLink: ['/social/facebook'],
          },
        ],
      },
    ],
  },
];
