import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, tap } from 'rxjs';

import { environment } from '@env/environment';
import { ReportQuery } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class PdfDocumentService {
  private readonly baseUrl: string;
  private readonly v2BaseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v1/report`;
    this.v2BaseUrl = `${environment.baseUrl}/v2/report`;
  }

  downloadOverviewReport(query: { hotelId: string; dateFrom: string | Date; dateTo: string | Date; exportType: string; }): Observable<HttpResponse<Blob>> {
    const params = {
      hotel_id: query.hotelId,
      date_from: query.dateFrom,
      date_to: query.dateTo,
      // export_type: query.exportType
    }

    return this.http
      .post(`${this.v2BaseUrl}/overview`, params, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((res) => this.handleFileResponse(res)));
  }

  downloadNoteOverviewReport(query: string): Observable<HttpResponse<Blob>> {
    const params = new HttpParams().append('note_id', query);

    return this.http
      .post(`${this.v2BaseUrl}/note_overview`, params, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((res) => this.handleFileResponse(res)));
  }

  downloadChannelMixReport(query: ReportQuery): Observable<HttpResponse<Blob>> {
    const params = this.httpParams(query);
    return this.http
      .post(`${this.v2BaseUrl}/channel_mix`, params, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((res) => this.handleFileResponse(res)));
  }

  downloadChannelMixDetailReport(query: ReportQuery): Observable<HttpResponse<Blob>> {
    const params = this.httpParams(query);
    return this.http
      .post(`${this.v2BaseUrl}/channel_mix_detail`, params, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((res) => this.handleFileResponse(res)));
  }

  downloadVisitsRevenueReport(query: ReportQuery): Observable<HttpResponse<Blob>> {
    const params = this.httpParams(query);
    return this.http
      .post(`${this.v2BaseUrl}/visits_rev`, params, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((res) => this.handleFileResponse(res)));
  }

  downloadVisitsRevenueDetailReport(query: ReportQuery): Observable<HttpResponse<Blob>> {
    const params = this.httpParams(query);
    return this.http
      .post(`${this.v2BaseUrl}/visits_rev_detail`, params, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((res) => this.handleFileResponse(res)));
  }

  downloadSourceTrafficReport(query: ReportQuery): Observable<HttpResponse<Blob>> {
    const params = this.httpParams(query);
    return this.http
      .post(`${this.v2BaseUrl}/source_traffic`, params, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((res) => this.handleFileResponse(res)));
  }

  downloadToRefDomainReport(query: ReportQuery): Observable<HttpResponse<Blob>> {
    const params = this.httpParams(query);
    return this.http
      .post(`${this.v2BaseUrl}/top_ref`, params, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((res) => this.handleFileResponse(res)));
  }

  downloadSocialMediaReport(query: ReportQuery): Observable<HttpResponse<Blob>> {
    const params = this.httpParams(query);
    return this.http
      .post(`${this.v2BaseUrl}/social-media`, params, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((res) => this.handleFileResponse(res)));
  }

  downloadPaidMediaOverviewReport(
    query: ReportQuery,
    mediaId?: string,
    sourceId?: string[]
  ): Observable<HttpResponse<Blob>> {
    const params = this.httpParams(query);
    
    if (mediaId){
      params['mediaId'] = mediaId
    }

    if (sourceId){
      params['sourceId'] = sourceId.join(',')
    }

    return this.http
      .post(`${this.v2BaseUrl}/paid_media`, params, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((res) => this.handleFileResponse(res)));
  }

  downloadPaidMediaDetailReport(query: ReportQuery): Observable<HttpResponse<Blob>> {
    const params = this.httpParams(query);
    return this.http
      .post(`${this.v2BaseUrl}/paid_media_detail`, params, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(tap((res) => this.handleFileResponse(res)));
  }

  private httpParams(query: ReportQuery): { [key: string]: any } {
    const params: any = {
      dateFrom: query.dateFrom,
      dateTo: query.dateTo,
      enterprises: query.enterprises.join(','),
      hotels: query.hotels.join(','),
      brands: query.brands.join(','),
      predefineDate: query?.predefineDate ?? '',
      is_compare: !!query.isCompare
    }

    if (query.hotelId){
      params['hotel_id'] = query.hotelId
    }

    if (query.exportType){
      params['exportType'] = query.exportType
    }

    if (query.sourceId){
      params['sourceId'] =  query.sourceId.join(',')
    }
    
    if (query.isCompare){
      params['compDateFrom'] = query.compDateFrom
      params['compDateTo'] = query.compDateTo
      params['defaultMonthDate'] = query.defaultMonthDate
    }

    if (query.isOverview){
      params['is_overview'] = query.isOverview
    }

    return params;
  }

  

  downloadScorecardReport(query: { 
    hotelId: string; 
    date: string; 
    enterpriseId: string | undefined; 
    exportType: string;
    benchmarks?: string[];
  }): Observable<any> {
    let params = new HttpParams()
      .append('hotel_id', query.hotelId)
      .append('date', query.date)
      .append('export_type', query.exportType);
  
    if (query.enterpriseId) {
      params = params.append('enterprise_id', query.enterpriseId);
    }
  
    if (query.benchmarks && query.benchmarks.length > 0) {
      params = params.append('benchmarks', query.benchmarks.join(','));
    }
  
    return this.http.get(`${this.baseUrl}/scorecard`, {
      params,
      observe: 'response',
      responseType: 'blob',
    });
  }

  handleFileResponse(response: HttpResponse<Blob>): void {
    const contentDispositionHeader = response.headers.get('Content-Disposition');

    let fileName = '';
    if (contentDispositionHeader) {
      const matches = /filename=([^;]+)/i.exec(contentDispositionHeader);
      fileName = matches && matches[1] ? matches[1] : 'downloaded-file';

      // Use the filename as needed, for example:
      // console.log('File name:', fileName);
    }

    // The actual Blob content is in response.body
    // You can do something with the Blob, such as creating a download link
    if (response?.body) {
      const downloadLink = document.createElement('a');
      const blobUrl = URL.createObjectURL(response.body);
      downloadLink.href = blobUrl;
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(blobUrl);
    }
  }
}
